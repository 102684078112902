<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">出入库明细列表</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="del(row, index, done)" @row-save="handleSave">
                    <template slot="menuLeft" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <!--<el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
        <span>单据日期:</span>
        <el-input v-model="DocumentDate" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
    </el-col>-->
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>单据编号:</span>
                                    <el-input v-model="documentNoVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="DocumentTypeSpan">单据类型:</span>
                                    <el-select v-model="documentTypeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="documentTypechang" clearable>
                                        <el-option v-for="item in this.DJData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>批号:</span>
                                    <el-input v-model="batchNumberVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>物料名称:</span>
                                    <el-input v-model="materialNameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>物料大类:</span>
                                    <el-input v-model="materialCategoryVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="WarehouseSpan">仓库:</span>
                                    <el-select v-model="warehouseVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="warehousechang" clearable>
                                        <el-option v-for="item in this.CKData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 25px; text-align: right; align-self: flex-end;">
                                <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary" @click="searchFrom">  {{$t('Search')}}</el-button>
                                <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addInfo"> {{ $t('New') }}</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="medium" :title="$t('Edit')" @click="editInfo(row)"><span style="font-size:14px">{{$t('Edit')}}</span></el-button>
                        <el-button type="text" size="medium" :title="$t('Delete')" @click="delInfo(row)"><span style="font-size:14px">{{$t('Delete')}}</span></el-button>
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style>
</style>
<script>
    import { listInfo, delDetail, settlementMethodDDL, codeIsExist } from "@/api/stock/stockInoutDetail";

    export default {
        data() {
            return {
                obj: {},
                documentNoVal: "",
                documentTypeVal: "",
                batchNumberVal: "",
                materialNameVal: "",
                materialCategoryVal: "",
                warehouseVal: "",
                paramDocumentNo:"",
                tableData: [],
                settlementMethodData: [],
                editBtnText: this.$t('Edit'),
                colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                },
                DJData: [
                    {
                        value: 0,
                        label: '入库',
                        num: 0
                    }, {
                        value: 1,
                        label: '出库',
                        num: 1
                    }
                ],
                CKData: [
                    {
                        value: '仓库A',
                        label: '仓库A',
                        num: 1
                    }, {
                        value: '仓库B',
                        label: '仓库B',
                        num: 2
                    }, {
                        value: '仓库C',
                        label: '仓库C',
                        num: 3
                    }]
            }
        },
        computed: {
            setData() {

                const ValidateAmount = (rule, value, callback) => {
                    if (value != '' && value != null) {
                        if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
                            callback('请输入数字，金额输入不能小于0，最多只可保留2位小数')
                        } else {
                            callback()
                        }
                    } else {
                        callback()
                    }
                }
                var validateCode = (rule, value, callback) => {
                    if (value) {
                        if (/[\u4E00-\u9FA5]/g.test(value)) {
                            callback(new Error('不能包含中文!'))
                        } else {
                            callback()
                        }
                    }
                };
                var validateCodeExist = (rule, value, callback) => {
                    if ((value != '' || value != null) && (this.obj.id == '' || this.obj.id == null)) {
                        codeIsExist(value).then(resu => {
                            var IsExist = resu.data;
                            if (IsExist) {
                                callback(new Error(this.$t('CodeIsExist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                    }
                };

                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        header: true,
                        /* labelPosition:'top',*/
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        column: [
                            {
                                label: "单据日期",
                                prop: 'documentDate',
                                type: "date",
                                format: 'yyyy-MM-dd',
                                valueFormat: 'yyyy-MM-dd',
                            },
                            {
                                label: "单据编号",
                                prop: 'documentNo',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            },
                            //{
                            //    label: "明细单据编号",
                            //    prop: 'documentDetailNo',
                            //    placeholder: ' ',
                            //    rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            //},
                            {
                                label: "单据类型",
                                prop: 'documentType',
                                type: "select",
                                filterable: true,
                                value: 0,
                                dicData: [{
                                    label: '入库',
                                    value: 0
                                }, {
                                    label: '出库',
                                    value: 1
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                           
                            {
                                label: "物料编码",
                                prop: 'materialCode',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: 'BM0001',
                                    value: '0'
                                }, {
                                    label: 'BM0002',
                                    value: '1'
                                }, {
                                    label: 'BM0003',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "物料名称",
                                prop: 'materialName',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: 'BM0001',
                                    value: '0'
                                }, {
                                    label: 'BM0002',
                                    value: '1'
                                }, {
                                    label: 'BM0003',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "出入库数量",
                                prop: 'quantity',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "规格型号",
                                prop: 'specification',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: '型号A',
                                    value: '0'
                                }, {
                                    label: '型号B',
                                    value: '1'
                                }, {
                                    label: '型号C',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "计量单位",
                                prop: 'measureUnit',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: '单位A',
                                    value: '0'
                                }, {
                                    label: '单位B',
                                    value: '1'
                                }, {
                                    label: '单位C',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "批号",
                                prop: 'batchNumber',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: 'PH1000A',
                                    value: '0'
                                }, {
                                    label: 'PH1000B',
                                    value: '1'
                                }, {
                                    label: 'PH1000C',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "物料大类",
                                prop: 'materialCategory',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: '成品',
                                    value: '成品'
                                }, {
                                    label: '原料',
                                    value: '原料'
                                }, {
                                    label: '半成品',
                                    value: '半成品'
                                }, {
                                    label: '下脚料',
                                    value: '下脚料'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "物料分类",
                                prop: 'materialClassify',
                                type: "select",
                                filterable: true,
                                value: '成品竹节纱',
                                dicData: [{
                                    label: '成品竹节纱',
                                    value: '成品竹节纱'
                                }, {
                                    label: '成品混纺纱',
                                    value: '成品混纺纱'
                                }, {
                                    label: '进口棉花',
                                    value: '进口棉花'
                                    }, {
                                    label: '内地棉花',
                                    value: '内地棉花'
                                    }, {
                                    label: '半成品平纱',
                                    value: '半成品平纱'
                                    }, {
                                    label: '半成品混纺纱',
                                    value: '半成品混纺纱'
                                    }, {
                                    label: '下脚料',
                                    value: '下脚料'
                                    }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "出入库类型",
                                prop: 'inoutStockType',
                                type: "select",
                                filterable: true,
                                value: 0,
                                dicData: [{
                                    label: '原料采购(入库)',
                                    value: 0
                                }, {
                                    label: '成品生产(入库)',
                                    value: 1
                                }, {
                                    label: '成品退货(入库)',
                                    value: 2
                                }, {
                                    label: '成品销售(出库)',
                                    value: 3
                                }, {
                                    label: '生产投料(出库)',
                                    value: 4
                                }],
                                placeholder: ' ',
                                width: 120,
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "仓库",
                                prop: 'warehouse',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: '仓库A',
                                    value: '0'
                                }, {
                                    label: '仓库B',
                                    value: '1'
                                }, {
                                    label: '仓库C',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "仓位",
                                prop: 'storingLocation',
                                type: "select",
                                filterable: true,
                                value: '0',
                                dicData: [{
                                    label: '仓库A',
                                    value: '0'
                                }, {
                                    label: '仓库B',
                                    value: '1'
                                }, {
                                    label: '仓库C',
                                    value: '2'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }
                        ]
                    }

                }
            }
        },
        created() {
            this.init()
            this.getList();
        },
        methods: {
            init() {
                this.paramDocumentNo = this.$route.query.paramDocumentNo;
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
                else if (obj.currentTarget === null) {
                   
                    if (this.DocumentType === "" || this.DocumentType === null) {
                        document.querySelector("#DocumentTypeSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                    if (this.Warehouse === "" || this.Warehouse === null) {
                        document.querySelector("#WarehouseSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            //选中值改变--单据类型
            documentTypechang() {
                if (this.DocumentType === "") {
                    document.querySelector("#DocumentTypeSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#DocumentTypeSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },
            //选中值改变--仓库
            warehousechang() {
                if (this.Warehouse === "") {
                    document.querySelector("#WarehouseSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#WarehouseSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//搜索
            searchFrom() {
                this.getList();
            },
            //显示数据
            async getList() {
                await listInfo(this.paramDocumentNo,this.documentDateVal, this.documentNoVal, this.documentTypeVal, this.warehouseVal, this.inoutStockTypeVal, this.page.currentPage, this.page.pageSize).then(res => {
                    var cardata = res.data.stockInoutDetails;
                    this.tableData = cardata;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            //删除
            delInfo(row) {
                this.$confirm(this.$t('IsDel') + ":" + row.documentNo, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delDetail(row.id).then((res) => {
                        this.getList();
                        //待删除
                        var data = res.data;
                        this.$message({ showClose: false, message: data.message, type: "success" });
                        //this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                });
            },
            //修改
            editInfo(row) {
                this.$router.push({ path: '/stock/stockInoutAdd', query: { sign: 'edit', id: row.id, documentNo: row.documentNo } })
            },
            //新增
            addInfo() {
                this.$router.push({ path: '/stock/stockInoutAdd', query: { sign: 'add', id: "", documentNo: "" } })
            },
        }
    }
</script>